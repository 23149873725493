<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入订单号/商品名" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.status" clearable placeholder="请选择状态" size="small">
          <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.store" clearable placeholder="请选择门店" size="small">
          <el-option
              v-for="item in storeList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.supplier" placeholder="请选择供应商" size="small">
          <el-option
              v-for="item in supplierList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="时间">
        <el-date-picker size="small"
            v-model="dateRange"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="mg-bt-10">
<!--      <el-button type="primary" size="small" @click="sureDeliveryAll()">合并发货</el-button>-->
      <el-button type="danger" size="small" @click="orderCancelAll()">批量取消</el-button>
    </div>
    <el-table
        :data="tableData"
        ref="rfTable"
        border
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="id"
          label="ID"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="order_sn"
          label="订单号"
          width="130">
      </el-table-column>
      <el-table-column
          prop="goods"
          label="商品"
          width="450">
        <template slot-scope="scope">
          <table width="100%" class="gdtable">
            <tr v-for="(item,index) in scope.row.goods" :key="index" style="background-color: unset;border-bottom: 1px solid #f5f5f5">
              <td style="padding-bottom: 10px;width: 50px;"><img :src="item.goods_img" style="width: 40px;height: 40px;"></td>
              <td style="text-align: left;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">{{item.goods_name}} <span v-if="item.specs_name">/ {{item.specs_name}}</span></td>
              <td style="width: 200px; text-align: right">￥{{item.price}} x{{item.quantity}}<br><span v-if="item.hourse_name">发货仓库：{{item.hourse_name}}</span></td>
            </tr>
          </table>
        </template>
      </el-table-column>
      <el-table-column
          prop="store_str"
          label="下单门店"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="user_id_str"
          label="下单人"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="address"
          label="收货地址"
          align="left"
          width="190">
        <template slot-scope="scope">
          <span>{{scope.row.address.name}} {{scope.row.address.mobile}}<br>
            {{scope.row.address.province_name}}{{scope.row.address.city_name}}{{scope.row.address.district_name}}{{scope.row.address.address}}
            <el-button type="text" id="copyId" @click="copyTxt(scope.row.address.name + '，' + scope.row.address.mobile + '，' + scope.row.address.province_name+scope.row.address.city_name+scope.row.address.district_name+scope.row.address.address)" size="small">复制</el-button>
          </span>
        </template>
      </el-table-column>
      <el-table-column
          prop="sale_user_id_str"
          label="销售"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="remark"
          label="用户留言"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="goods_amount"
          label="商品价格"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="balance_amount"
          label="预存款抵扣"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="ump_amount"
          label="优惠价格"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="amount"
          label="实付款"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="status_str"
          label="订单状态"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="package"
          label="发货信息"
          align="left"
          width="160">
        <template slot-scope="scope">
          <span v-if="scope.row.package">{{scope.row.package.logis_name}}<br>{{scope.row.package.logis_no}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="下单时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <div v-if="scope.row.status==0">
            <el-button type="text" size="small" @click="dialogVisible=true; itemInfo=JSON.parse(JSON.stringify(scope.row))">改价</el-button>
            <el-popconfirm
                title="确认取消该订单吗？"
                @confirm="orderCancel([scope.row.id])">
              <el-button type="text" size="small" slot="reference">取消</el-button>
            </el-popconfirm>
          </div>
          <div v-if="scope.row.status==1">
<!--            <el-button type="text" size="small" @click="dialogPayVisible=true; itemInfo=JSON.parse(JSON.stringify(scope.row))">支付</el-button>-->
            <el-popconfirm
                title="确认取消该订单吗？"
                @confirm="orderCancel([scope.row.id])">
              <el-button type="text" size="small" slot="reference">取消</el-button>
            </el-popconfirm>
          </div>
          <div v-if="scope.row.status==2">
<!--            <el-button type="text" size="small" @click="dialogDeliveryVisible=true; delivery.type=1; delivery.ids=[scope.row.id]; itemInfo=JSON.parse(JSON.stringify(scope.row))">发货</el-button>-->
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog
        title="修改价格"
        :visible.sync="dialogVisible"
        width="30%">
      <span>请输入实付价格：</span>
      <el-input v-model="itemInfo.amount" placeholder="请输入实付价格" class="mg-tp-10" size="small"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="changePrice" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="确认支付"
        :visible.sync="dialogPayVisible"
        width="30%">
      <span>支付凭证：</span>
      <div class="mg-20">
        <img :src="item" v-for="(item,index) in itemInfo.pay_pic" :key="index" width="80" height="80" class="mg-rt-10">
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPayVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="surePay" size="small">确认支付</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="发货"
        :visible.sync="dialogDeliveryVisible"
        width="30%">
      <div style="margin-bottom: 10px;font-size: 14px;">请选择快递：</div>
      <el-select v-model="delivery.logis_code" placeholder="请选择快递" size="small">
        <el-option
            v-for="item in setInfo.delivery"
            :key="item.id"
            :label="item.title"
            :value="item.id">
        </el-option>
      </el-select>
      <div style="margin-bottom: 10px; margin-top: 20px;font-size: 14px;">请填写快递单号：</div>
      <el-input v-model="delivery.logis_no" placeholder="请填写快递单号" size="small"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDeliveryVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="sureDelivery()" size="small">确认发货</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import ClipboardJS from "clipboard";
export default {
  components: {},
  data() {
    return {
      info: false,
      dialogVisible: false,
      dialogPayVisible: false,
      dialogDeliveryVisible: false,
      dateRange: ['',''],
      supplierList: [],
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        status: '',
        store: '',
        supplier: ''
      },
      tableData: [],
      statusList: [
        {id:1,title:'待付款'},
        {id:2,title:'待确认'},
        {id:3,title:'待发货'},
        {id:4,title:'待收货'},
        {id:5,title:'已完成'},
        {id:6,title:'已取消'},
      ],
      storeList: [],
      itemInfo: false,
      setInfo: false,
      delivery: {
        type: 1,
        ids: [],
        logis_code: '',
        logis_no: ''
      }
    };
  },
  created() {
    this.getSupplierList()
    this.getSetInfo()
    this.getStoreList()
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    getSupplierList() {
      this.$api.merchant.supplierIndex({page_size: 1000}, res => {
        if (res.errcode == 0) {
          this.supplierList = res.data.data
        }
      })
    },
    getSetInfo() {
      this.$api.merchant.sysSet(res => {
        if (res.errcode == 0) {
          this.setInfo = res.data
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getStoreList() {
      this.$api.merchant.storeIndex({page_size: 1000}, res => {
        if (res.errcode == 0) {
          this.storeList = res.data.data
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        status: that.search.status,
        store: that.search.store,
        supplier: that.search.supplier,
        start_time: this.dateRange[0],
        end_time: this.dateRange[1],
      }
      this.$api.order.orderIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    copyTxt(txt) {
      var _this = this
      var clipboard = new ClipboardJS('#copyId', {
        text: function () {
          return txt;
        },
      });
      clipboard.on('success', function (e) {
        _this.success('复制成功')
      });
      clipboard.on('error', function (e) {
        _this.fail('复制失败')
      });
    },
    changePrice() {
      this.$api.order.orderSetump({id: this.itemInfo.id, amount: this.itemInfo.amount}, res => {
        if (res.errcode == 0) {
          this.dialogVisible = false
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    surePay() {
      this.$api.order.orderSurepay({id: this.itemInfo.id}, res => {
        if (res.errcode == 0) {
          this.dialogPayVisible = false
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    orderCancelAll() {
      var _this = this
      var sel = this.$refs.rfTable.selection
      if (sel.length < 1) {
        _this.tips('请选择删除项')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.$confirm('确认取消这些订单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.orderCancel(ids)
      }).catch(() => {

      });
    },
    orderCancel(ids) {
      var _this = this
      this.$api.order.orderCancel({ids: ids}, res => {
        if (res.errcode == 0) {
          _this.success('取消成功')
          _this.getList()
        } else {
          _this.fail(res.errmsg)
        }
      })
    },
    sureDeliveryAll() {
      var _this = this
      var sel = this.$refs.rfTable.selection
      if (sel.length < 1) {
        _this.tips('请选择发货项')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.$confirm('确定多订单一起发货吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.delivery.type = 2
        _this.delivery.ids = ids
        _this.dialogDeliveryVisible = true
      }).catch(() => {

      });
    },
    sureDelivery() {
      if(this.delivery.logis_code == '') {
        this.fail('请选择快递')
        return false
      }
      if(this.delivery.logis_no == '') {
        this.fail('请填写快递单号')
        return false
      }
      this.$api.order.orderDelivery({ids: this.delivery.ids,logis_code:this.delivery.logis_code,logis_no:this.delivery.logis_no}, res => {
        if (res.errcode == 0) {
          this.delivery.logis_code = ''
          this.delivery.logis_no = ''
          this.dialogDeliveryVisible = false
          this.success('发货成功')
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
.gdtable tr:last-child {
  border-bottom: unset !important;
}
</style>
